import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextButtonComponent } from '../button/text-button/text-button.component';
import { TranslocoModule } from '@jsverse/transloco';

export interface ConfirmModel {
  title: string | undefined;
  message: string | undefined;
}

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
  standalone: true,
  imports: [TranslocoModule, TextButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent {
  constructor(
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string },
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
